<template>
  <div class="indexLayout">
    <NuxtPage />

    <LayoutFooter class="indexLayout__footer" />
  </div>
</template>

<style lang="scss">
.indexLayout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__footer {
    margin-top: auto;
  }
}
</style>
